import React, { FC, memo } from 'react';
import { useTheme } from '@material-ui/core/styles';

import { ProgressBarProps } from './progressBar.types';
import { useProgressBar } from './progressBar.hook';

export const ProgressBar: FC<ProgressBarProps> = memo((props) => {
  const { palette } = useTheme();

  useProgressBar(props);

  return (
    <style jsx global>{`
      #nprogress {
        pointer-events: none;
      }

      #nprogress .bar {
        background: ${palette.secondary.main};
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
      }

      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px ${palette.secondary.main},
          0 0 5px ${palette.secondary.main};
        opacity: 1;
        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
      }

      #nprogress {
        display: 'block';
        position: fixed;
        z-index: 1031;
        top: 15px;
        right: 15px;
      }

      .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
      }

      .nprogress-custom-parent #nprogress .bar {
        position: absolute;
      }
    `}</style>
  );
});
