import styled from 'styled-components';
import Typography, { TypographyTypeMap } from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const Decoration: OverridableComponent<TypographyTypeMap> = styled(
  Typography
)`
  ${({ theme: { palette } }) => `
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    border-bottom: 1px dashed ${palette.text.primary};
    cursor: pointer;
  `}
`;

export const Text = styled(Typography)`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;
