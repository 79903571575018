import { FC, memo, useMemo } from 'react';
import Box from '@material-ui/core/Box';

import { TabData, TabsProps, TabPanelProps, OnChange } from './tabs.types';
import { Tabs as UITabs, Tab, TabPanelContent } from './tabs.styles';

type RenderTab = (tabData: TabData, key: number) => JSX.Element;

const a11yProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const renderTab: RenderTab = ({ value, label }, key) => (
  <Tab key={value} label={label} value={value} {...a11yProps(key)} />
);

export const TabPanel: FC<TabPanelProps> = memo(
  ({ children, value, index }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {value === index && <TabPanelContent>{children}</TabPanelContent>}
    </div>
  )
);

export const Tabs: FC<TabsProps> = memo(
  ({ children, ariaLabel, value, data, className, onChange }) => {
    const customValue = useMemo(() => (value === null ? false : value), [
      value,
    ]);

    const handleChange: OnChange = (e, newValue) => {
      onChange(newValue);
    };

    return (
      <>
        <Box component="header" className={className}>
          <UITabs
            value={customValue}
            onChange={handleChange}
            aria-label={ariaLabel}
            variant="scrollable"
            scrollButtons="off"
          >
            {data.map(renderTab)}
          </UITabs>
        </Box>
        {children}
      </>
    );
  }
);
