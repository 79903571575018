import React, { createContext, FC, useCallback, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import styled from 'styled-components';

interface ClickAwayContextProps {
  open: boolean;
  handleToggleOpen: (...args: any[]) => void;
  handleClose: (...args: any[]) => void;
}

export const ClickAwayContext = createContext<ClickAwayContextProps>({} as any);

const ClickAwayWrapper = styled.div`
  position: relative;
`;

const ClickAwayChildWrapper = styled.div<{ stick: 'left' | 'right' }>`
  position: absolute;
  top: 100%;
  ${({ stick }) => stick}: 0;
  z-index: ${({ theme: { zIndex } }) => zIndex.speedDial};
`;

interface ClickAwayProps {
  stick?: 'left' | 'right';
  component: (props: {
    open: boolean;
    onClick: (...args: unknown[]) => void;
  }) => JSX.Element;
}

export const ClickAway: FC<ClickAwayProps> = ({
  component: Component,
  stick = 'left',
  children,
}) => {
  const [open, setOpen] = useState(false);
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);
  const handleClose = useCallback(
    (event) => {
      // https://github.com/mui-org/material-ui/issues/18586
      if (event.target.nodeName === 'BODY' && event.type === 'click') {
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );
  const context = { open, handleToggleOpen, handleClose };
  return (
    <ClickAwayContext.Provider value={context}>
      <ClickAwayListener onClickAway={handleClose}>
        <ClickAwayWrapper>
          <Component open={open} onClick={handleToggleOpen} />
          {open && (
            <ClickAwayChildWrapper stick={stick}>
              {children}
            </ClickAwayChildWrapper>
          )}
        </ClickAwayWrapper>
      </ClickAwayListener>
    </ClickAwayContext.Provider>
  );
};
