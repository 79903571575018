import styled from 'styled-components';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

export const Tabs = styled(MUITabs)`
  ${({ theme: { palette, colors, breakpoints } }) => `
    position: relative;
    min-height: 50px;    

    ${breakpoints.up('md')} {
      min-height: 42px;    
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: ${colors.greys.greys3};
      z-index: -1;
    }

    .MuiTabs-indicator {
      background-color: ${palette.secondary.main};
    }
  `}
`;

export const Tab = styled(MUITab)`
  ${({
    theme: {
      palette,
      typography: { pxToRem },
      transitions: { easing, duration },
      breakpoints,
    },
  }) => `
    min-height: 50px;
    min-width: auto;
    font-weight: 600;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(21)};
    color: ${palette.primary.main};
    transition: ${duration.standard}ms ${easing.easeInOut};
    opacity: 1;

    &:hover {
      color: ${palette.secondary.dark};
    }

    &.Mui-selected {
      color: ${palette.secondary.dark};
    }

    ${breakpoints.up('md')} {
      min-height: 42px;
    }
  `}
`;

export const TabPanelContent = styled(Box)`
  width: 100%;
  height: 100%;
`;
