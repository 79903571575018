import { useEffect, useRef } from 'react';
import NProgress, { NProgressOptions } from 'nprogress';
import Router from 'next/router';

import { ProgressBarProps } from './progressBar.types';

const DEFAULT_OPTIONS: Partial<NProgressOptions> = {
  showSpinner: false,
};

type UseProgressBar = (props: ProgressBarProps) => void;

export const useProgressBar: UseProgressBar = ({
  startPosition = 0.3,
  stopDelayMs = 200,
  options = {},
}) => {
  const timer = useRef<number | undefined>(undefined);

  const routeChangeStart = () => {
    NProgress.set(startPosition);
    NProgress.start();
  };

  const routeChangeEnd = () => {
    clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  useEffect(() => {
    NProgress.configure({
      ...DEFAULT_OPTIONS,
      ...options,
    });

    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);
  }, []);
};
