import styled from 'styled-components';
import { Button as MUIButton } from '@material-ui/core';

export const Button = styled(MUIButton)`
  ${({ theme: { typography } }) => `
    font-family: ${typography.fontFamily};
    font-size: ${typography.button.fontSize};
    line-height: ${typography.button.lineHeight};
    font-weight: ${typography.button.fontWeight};

    &.Mui-disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }

    &.MuiButton-containedSizeSmall {
      padding: 11px 17px;
    }

    &.MuiButton-containedSizeLarge {
      padding: 11px 50px;
    }
  `}
`;
