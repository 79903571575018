import styled from 'styled-components';
import { Link as MUILink } from '@material-ui/core';
import { LinkStyledProps } from './link.types';

export const Link = styled(MUILink)<LinkStyledProps>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { easing, duration },
    },
    $fullWidth,
  }) => `
    color: ${colors.blues.blues1};
    width: ${$fullWidth ? '100%' : 'auto'};
    display: inline-block;
    cursor: pointer;
    transition: ${duration.shortest}ms ${easing.easeInOut};

    &:hover, &:focus {
      color: ${palette.action.hover};
    }
  `}
`;
