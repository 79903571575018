import React, { FC } from 'react';
import { ButtonProps } from '@material-ui/core';

import { Button as UIButton } from './button.styles';

export const Button: FC<ButtonProps> = ({
  children,
  variant = 'contained',
  ...rest
}) => (
  <UIButton {...rest} variant={variant}>
    {children}
  </UIButton>
);
