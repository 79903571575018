import React from 'react';
import styled from 'styled-components';
import { Divider as MuiDivider, DividerProps } from '@material-ui/core';

const StyledDivider = styled(MuiDivider)<DividerProps & DividerCustomProps>`
  ${({ thickness, orientation }) => `
    ${orientation === 'horizontal' ? 'height' : 'width'}: ${
    thickness === 'bold' ? 2 : 1
  }px;
  `}
`;

interface DividerCustomProps {
  thickness?: 'bold' | 'default';
}

export const Divider = (props: DividerProps & DividerCustomProps) => {
  const {
    light = true,
    thickness = 'default',
    orientation = 'horizontal',
  } = props;
  return (
    <StyledDivider
      light={light}
      thickness={thickness}
      orientation={orientation}
      {...props}
    />
  );
};
