import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import MUITooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { Decoration, Text } from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

type RenderText = (text: string, key: number) => JSX.Element;

type RenderTexts = (texts: string[]) => JSX.Element;

const useStyles = makeStyles(({ palette, typography }) => {
  const tooltipPlacement = {
    fontSize: typography.body2.fontSize,
    lineHeight: typography.body2.lineHeight,
    fontWeight: typography.body2.fontWeight,
    margin: 0,
    padding: '25px 30px',
    color: palette.text.primary,
    backgroundColor: palette.common.white,
    boxShadow:
      '0px 4px 4px rgba(32, 59, 92, 0.07), 0px 7px 20px rgba(32, 59, 92, 0.1)',
  };

  return {
    popper: {
      zIndex: 9,
    },
    tooltipPlacementTop: tooltipPlacement,
    tooltipPlacementBottom: tooltipPlacement,
  };
});

const renderText: RenderText = (text, key) => (
  <Text key={key} variant="body2">
    {text}
  </Text>
);

const renderTexts: RenderTexts = (texts) => <Box>{texts.map(renderText)}</Box>;

export const Tooltip: FC<TooltipProps> = ({
  texts,
  children,
  withDecoration = false,
  ...restProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);

  const handleTooltipOpen = () => setOpen(true);

  return (
    <ClickAwayListener
      touchEvent="onTouchStart"
      onClickAway={handleTooltipClose}
    >
      <MUITooltip
        classes={classes}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={renderTexts(texts)}
        {...restProps}
      >
        {withDecoration ? (
          <Decoration component="span" onClick={handleTooltipOpen}>
            {children}
          </Decoration>
        ) : (
          <Box onClick={handleTooltipOpen}>{children}</Box>
        )}
      </MUITooltip>
    </ClickAwayListener>
  );
};
