import React, { FC } from 'react';
import NextLink from 'next/link';

import { Link as UILink } from './link.styles';
import { LinkProps } from './link.types';

export const Link: FC<LinkProps> = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  underline = 'none',
  isExternal = false,
  fullWidth = false,
  ...linkProps
}) => {
  if (isExternal) {
    return (
      <UILink href={href as string} underline={underline} {...linkProps}>
        {children}
      </UILink>
    );
  }

  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <UILink $fullWidth={fullWidth} underline={underline} {...linkProps}>
        {children}
      </UILink>
    </NextLink>
  );
};
